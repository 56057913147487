import Vue from 'vue'
import moment from 'moment'

import App from './App.vue'
import router from './router'

/* Styles import */
import '@/assets/styles/_global.scss'

/* Plugins import */
import '@/plugins/bootstrapConfig'
import '@/plugins/fontawesomeConfig'
import '@/plugins/veeValidateConfig'
import '@/plugins/registerServiceWorker'
import '@/plugins/vSelectConfig'
import '@/plugins/vueEditorConfig'
import '@/plugins/accountingConfig'
import '@/plugins/moneyConfig'
import vuetify from '@/plugins/vuetifyConfig'
import ProjectHub from '@/utils/projectHub'
import NotificationHub from '@/utils/notificationHub'
import InvoiceHub from '@/utils/invoiceHub'

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    moment
  }
})

Vue.use(ProjectHub)
  .use(NotificationHub)
  .use(InvoiceHub)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
