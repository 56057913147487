export default {
  path: 'employee',
  component: () => import('@/views/Route'),
  children: [
    {
      path: 'add-employee',
      name: 'Add Employee',
      component: () => import('@/views/menus/employee/AddEmployee'),
      meta: {
        permission: true
      }
    },
    {
      path: 'edit-profile',
      name: 'Edit Profile',
      component: () => import('@/views/profileEmployee/EditProfile'),
      meta: {
        permission: true
      }
    },
    {
      path: ':id',
      name: 'Edit Employee',
      component: () => import('@/views/menus/employee/EditEmployee'),
      meta: {
        permission: true
      }
    }
  ]
}
