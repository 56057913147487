import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'

export default {
  install(Vue) {
    const invoiceHub = new Vue()
    Vue.prototype.$invoiceHub = invoiceHub

    let connection = null
    let startedPromise = null
    let manuallyClosed = null

    connection = new HubConnectionBuilder()
      .withUrl(`${process.env.VUE_APP_BACKEND_URL}/notif/invoice`)
      .configureLogging(LogLevel.None)
      .build()

    function start() {
      startedPromise = connection.start()
        .catch(err => {
          console.error('Failed to connect with hub', err)
          return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000))
        })
      return startedPromise
    }

    if (!startedPromise) {
      // Forward hub events through the event, so we can listen for them in the Vue components
      connection.on('SendMessage', (message) => {
        invoiceHub.$emit('receive-push', message)
      })

      connection.on('ReceiveMessage', (message) => {
        invoiceHub.$emit('receive-all', message)
      })

      connection.onclose(() => {
        if (!manuallyClosed) start()
      })

      // Start everything
      manuallyClosed = false
      start()
    }

    Vue.prototype.stopSignalR = () => {
      if (!startedPromise) return

      manuallyClosed = true
      return startedPromise
        .then(() => connection.stop())
        .then(() => { startedPromise = null })
    }

    invoiceHub.receiveMessage = (data) => {
      if (!startedPromise) return

      return startedPromise
        .then(() => connection.invoke('ReceiveMessage', data))
        .catch('invoice hub' + console.error)
    }
  }
}
