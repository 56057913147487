export default {
  path: 'invoice-list',
  component: () => import('@/views/Route'),
  children: [
    {
      path: '',
      name: 'Invoice List',
      component: () => import('@/views/menus/invoiceList/Index'),
      meta: {
        permission: true
      }
    }
  ]
}
