import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faBars,
  faChartPie,
  faPen,
  faFileAlt,
  faSearch,
  faTimes,
  faTrashAlt,
  faShoppingCart,
  faFolder,
  faEye,
  faUserCircle,
  faSignOutAlt,
  faHome,
  faTruck,
  faShapes,
  faUserFriends,
  faLink,
  faCaretDown,
  faCaretRight,
  faTrash,
  faEdit,
  faClipboardList,
  faAngleUp,
  faAngleDown,
  faChevronUp,
  faChevronDown,
  faLayerGroup,
  faCodeBranch,
  faThLarge,
  faCaretUp
} from '@fortawesome/free-solid-svg-icons'
import {
  faTimesCircle,
  faBell,
  faCalendarAlt
} from '@fortawesome/free-regular-svg-icons'

library.add(
  faBars,
  faChartPie,
  faPen,
  faFileAlt,
  faSearch,
  faTimes,
  faTrashAlt,
  faShoppingCart,
  faFolder,
  faEye,
  faUserCircle,
  faSignOutAlt,
  faHome,
  faTruck,
  faShapes,
  faUserFriends,
  faLink,
  faCaretDown,
  faCaretRight,
  faTrash,
  faEdit,
  faClipboardList,
  faAngleUp,
  faAngleDown,
  faChevronUp,
  faChevronDown,
  faTimesCircle,
  faLayerGroup,
  faCodeBranch,
  faBell,
  faCalendarAlt,
  faThLarge,
  faCaretUp
)

Vue.component('fa-icon', FontAwesomeIcon)
