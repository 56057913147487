export default {
  path: 'manage-developer',
  component: () => import('@/views/Route'),
  children: [
    {
      path: '',
      name: 'Manage Developer',
      component: () => import('@/views/menus/manageDeveloper/Index'),
      meta: {
        permission: true
      }
    }
  ]
}
