import Vue from 'vue'
import VueRouter from 'vue-router'

// for implement permission menu
// import { hasPermission } from '@/utils/permissions'
import dashboard from './dashboard'
import editProject from './editProject'
import newProject from './newProject'
import projectList from './projectList'
import viewProject from './viewProject'
import invoiceList from './invoiceList'
import budgeting from './budgeting'
import summaryProfit from './summaryProfit'
import manageType from './type'
import manageClient from './manageClient'
import manageActivity from './manageActivity'
import manageExpenses from './expenses'
import manageDevelopmentRole from './developmentRole'
import employee from './employee'
import training from './training'
import generateProfile from './generateProfile'
import viewProfile from './viewProfile'
import manageEmployees from './manageEmployees'
import manageRole from './manageRole'
import manageUser from './manageUser'
import notifications from './notification'
import manageDeveloper from './manageDeveloper'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/Home'),
    children: [
      {
        path: '',
        redirect: () => '/dashboard'
      },
      { ...dashboard },
      { ...newProject },
      { ...projectList },
      { ...viewProject },
      { ...editProject },
      { ...invoiceList },
      { ...budgeting },
      { ...summaryProfit },
      { ...manageType },
      { ...manageClient },
      { ...manageActivity },
      { ...manageExpenses },
      { ...manageDevelopmentRole },
      { ...employee },
      { ...training },
      { ...generateProfile },
      { ...viewProfile },
      { ...manageEmployees },
      { ...manageUser },
      { ...manageRole },
      { ...notifications },
      { ...manageDeveloper },
      {
        path: 'timeline-by-project',
        name: 'Timeline by Project',
        component: () => import('@/views/menus/timeline/Project')
      },
      {
        path: 'timeline-by-employee',
        name: 'Timeline by Employee',
        component: () => import('@/views/menus/timeline/Employee')
      },
      {
        path: '403',
        name: 'Forbidden',
        component: () => import('@/views/403')
      }
    ]
  },
  {
    path: '/reset-password',
    component: () => import('@/views/Route'),
    children: [
      {
        path: '/reset-password/:id',
        name: 'Reset Password',
        component: () => import('@/views/ResetPassword'),
        meta: {
          permission: true
        }
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/Login')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && !to.path.includes('/reset-password') && !localStorage.getItem('token')) {
    next('/login')
  } else {
    next()
  }
})

export default router
