export default {
  path: 'view-profile',
  component: () => import('@/views/Route'),
  children: [
    {
      path: '',
      name: 'View Profile',
      component: () => import('@/views/profileEmployee/Index'),
      meta: {
        permission: true
      }
    }
  ]
}
