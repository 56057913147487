export default {
  path: 'training',
  component: () => import('@/views/Route'),
  children: [
    {
      path: 'add-training-employee',
      name: 'Add Training Employee',
      component: () => import('@/views/profileEmployee/trainingEmployee/AddTraining'),
      meta: {
        permission: true
      }
    },
    {
      path: ':id',
      name: 'Edit Training',
      component: () => import('@/views/profileEmployee/trainingEmployee/EditTraining'),
      meta: {
        permission: true
      }
    }
  ]
}
