export default {
  path: 'budgeting',
  component: () => import('@/views/Route'),
  children: [
    {
      path: '',
      name: 'Budgeting',
      component: () => import('@/views/menus/budgeting/Index'),
      meta: {
        permission: true
      }
    },
    {
      path: ':id',
      name: 'Edit Manage Budget',
      component: () => import('@/views/menus/budgeting/Edit'),
      meta: {
        permission: true
      }
    }
  ]
}
