export default {
  path: 'generate-profile',
  component: () => import('@/views/Route'),
  children: [
    {
      path: '',
      name: 'Generate Profile',
      component: () => import('@/views/profileEmployee/GenerateProfile'),
      meta: {
        permission: true
      }
    }
  ]
}
