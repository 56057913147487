export default {
  path: 'manage-activity',
  component: () => import('@/views/Route'),
  children: [
    {
      path: '',
      name: 'Manage Activity',
      component: () => import('@/views/menus/manageActivity/Index'),
      meta: {
        permission: true
      }
    }
  ]
}
